@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .placeholder-italic::placeholder {
    font-style: italic;
  }
}

:root {
  --droov-blue: #49c8ff;
  --droov-green: #57d737;
}

:focus {
  outline: none !important;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.customIndent:hover {
  padding-left: 2.5rem;
  transition: all 300ms ease-in-out;
}
/* hover:-translate-y-2 transition-all hover:scale-105 transform  */
.customPopup {
  @apply md:transform hover:-translate-y-2 transition-all hover:scale-105;
}

.loader {
  border-top-color: var(--droov-blue);
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.button_close:hover div {
  display: block;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.custom-number-input input:focus {
  outline: none !important;
}

.custom-number-input button:focus {
  outline: none !important;
}

.position_left_middle {
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.position_right_middle {
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
}

.droov_logo {
  background-image: url('assets/droov_logo_black.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.droov_logo_black {
  background-image: url('assets/droov_logo_black.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.table_style {
  height: 70vh;
}

/* 
.relative_red_button {
  @apply bg-red_button text-red_text rounded-3xl focus:outline-none;
} */

/* 4 important buttons blue_button(save button), cancel_button, add_button , reject_button(red_button) */

.blue_button {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-droov text-white rounded-button hover:bg-hover_blue duration-200 transition-colors ease-in delay-75 text-sm;
}

.blue_button:disabled {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-droov opacity-50 text-white rounded-button text-sm pointer-events-none;
}

.blue_disabled_btn {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-droov opacity-50 text-white rounded-button text-sm;
}

.cancel_button {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-transparent text-gray-600 rounded-button hover:border-gray-600 duration-200 transition-colors ease-in-out border-transparent border text-sm flex justify-center items-center;
}
.add_button {
  @apply border-2 border-droov text-droov cursor-pointer rounded-button w-48 mb-4 p-3 px-4 h-9 flex justify-center items-center duration-200  hover:bg-droov hover:text-white text-sm;
}

.feedback_button {
  @apply border-2 shadow-sm text-black cursor-pointer rounded-button  px-3 py-2 flex justify-center items-center  duration-200 text-sm hover:border-droov hover:text-droov;
}

.order_blue_button {
  @apply flex justify-center items-center bg-droov text-white rounded-lg px-5 mt-4 mb-0.5 h-10 p-1 h-10 hover:bg-hover_blue duration-200 transition-colors ease-in-out text-sm;
}

.order_yellow_button {
  @apply flex justify-center items-center bg-yellow-200 text-black rounded-lg px-5 mt-4 mb-0.5 h-10 hover:bg-yellow-400 duration-200 transition-colors ease-in-out text-sm;
}

.order_green_button {
  @apply flex justify-center items-center bg-green_chip text-white rounded-lg px-5 mt-4 mb-0.5 h-10 hover:bg-green-600 duration-200 transition-colors ease-in-out text-sm;
}

.relative_add_button {
  @apply border-2 border-droov text-droov cursor-pointer rounded-button  p-3 flex justify-center items-center  duration-200 text-sm hover:bg-droov hover:text-white;
}

.relative_add_button_no_pad {
  @apply border-2 border-droov text-droov cursor-pointer rounded-button flex justify-center items-center duration-200 text-sm hover:bg-droov hover:text-white;
}

.relative_red_button {
  @apply bg-red_button text-red_text hover:bg-red-500 hover:text-white rounded-3xl focus:outline-none duration-200  transition-colors ease-in-out text-sm flex justify-center items-center;
}

.catering_reject_button {
  @apply flex justify-center items-center bg-red_button text-red_text px-5 h-10 rounded-lg text-sm;
}
.catering_reject_button_on_hover {
  @apply hover:bg-red-500 hover:text-white duration-200 transition-colors ease-in-out;
}

.catering_confirm_button {
  @apply flex justify-center items-center bg-green_chip text-white rounded-lg px-5 h-10 text-sm;
}
.catering_confirm_button_on_hover {
  @apply hover:bg-green-600 duration-200 transition-colors ease-in-out;
}

.relative_blue_button {
  @apply bg-droov text-white rounded-button hover:bg-hover_blue duration-200 transition-colors ease-in delay-75 text-sm flex justify-center items-center;
}

.relative_blue_button:disabled {
  @apply pointer-events-none opacity-50;
}

.relative_clear_button {
  @apply text-white rounded-button duration-200 transition-colors ease-in-out text-sm flex justify-center items-center;
}

.relative_white_button {
  @apply bg-white text-gray-600 hover:bg-gray-200 rounded-button duration-200 transition-colors ease-in-out text-sm flex justify-center items-center;
}

.red_button {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-red_button text-red_text hover:bg-red-500 hover:text-white rounded-3xl focus:outline-none duration-200 transition-colors ease-in delay-75 text-sm;
}

.green_button {
  min-width: 6rem;
  max-width: 12rem;
  @apply px-6 py-2 bg-green_chip text-white hover:bg-green-600 rounded-3xl focus:outline-none duration-200 transition-colors ease-in delay-75 text-sm;
}

.red_button:disabled {
  @apply pointer-events-none bg-red-400;
}

/* Required to override cursor style of a component.  */
/* Inline styles are overriden by the base styles of tailwind. */
.draggable_button {
  cursor: grab;
}

.order_tab_button {
  cursor: pointer;
}

/* font sizes */
/* text-2xl font-medium */
.droov_xxlarge_text {
  @apply text-2xl font-medium;
}

.droov_label_text {
  @apply font-medium text-sm text-gray-700;
}

.droov_text_description_light {
  @apply font-light text-gray-500;
}

/* text box focus style */
/* focus:ring-droov focus:border-droov block w-full shadow-sm sm:text-sm border-gray-300 rounded-md */
.text_box_focus_style {
  /* --tw-border-opacity: 1; */
  /* --tw-ring-color: rgba(var(--droov-blue), var(--tw-border-opacity)); */
  border-color: pink;
}

.login_background {
  background: linear-gradient(180deg, #f6f6f6 50%, #f5f5f5 50%);
  background: -moz-linear-gradient(180deg, #f6f6f6 50%, #f5f5f5 50%);
  background: -webkit-linear-gradient(180deg, #f6f6f6 50%, #f5f5f5 50%);
}

@-webkit-keyframes img_appear_motion_rightToLeft {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes img_appear_motion_rightToLeft {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes img_appear_motion_leftToRight {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes img_appear_motion_leftToRight {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@-webkit-keyframes appear_motion_bottomToUp {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes appear_motion_bottomToUp {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes appear_motion_upToBottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes appear_motion_upToBottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.focus\:ring-droov-form-input-border-onfocus:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(74, 200, 255, var(--tw-ring-opacity));
}

.animate-img_appear_motion_rightToLeft {
  -webkit-animation: img_appear_motion_rightToLeft 2s;
  animation: img_appear_motion_rightToLeft 2s;
}

.animate-img_appear_motion_leftToRight {
  -webkit-animation: img_appear_motion_leftToRight 2s;
  animation: img_appear_motion_leftToRight 2s;
}

.animate-appear_motion_bottomToUp {
  -webkit-animation: appear_motion_bottomToUp 2s;
  animation: appear_motion_bottomToUp 2s;
}

.text-droov-contact-bottom-color {
  --tw-text-opacity: 1;
  color: rgba(147, 147, 147, var(--tw-text-opacity));
}

.btn_black {
  display: flex;
  height: 4rem;
  width: 10rem;
  align-items: center;
  justify-content: center;
  border-radius: 31px;
  --tw-bg-opacity: 1;
  background-color: rgba(5, 25, 35, var(--tw-bg-opacity));
}

.btn_black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(65, 66, 66, var(--tw-bg-opacity));
}

.btn_black {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn_black:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.btn_black {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.text-droov-forgot-text-color {
  --tw-text-opacity: 1;
  color: rgba(182, 182, 182, var(--tw-text-opacity));
}

:root {
  --avatar-size: 2.5rem;
  /* change this value anything, e.g., 100px, 10rem, etc. */
}

.circle {
  background-color: #ccc;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);
}

.item_box_shadow {
  box-shadow: 0px 2px 14px 3px rgb(0 0 0 / 10%);
}

.chip_box_shadow {
  box-shadow: 0px 2px 8px 1px rgb(0 0 0 / 6%);
}

.invoice:not(:last-child)::after {
  content: '';
  height: 1px;
  position: absolute;
  width: 100%;
  background-color: #efefef;
  bottom: 0;
  left: 0;
  right: 0;
}
.fade_in_text {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.loaderOrderTab {
  margin: auto;
  border: 20px solid #eaf0f6;
  border-radius: 50%;
  border-top: 20px solid var(--droov-blue);
  width: 150px;
  height: 150px;
  -webkit-animation: spinnerOrderTab 4s linear infinite;
  animation: spinnerOrderTab 4s linear infinite;
}

@-webkit-keyframes spinnerOrderTab {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinnerOrderTab {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.droov_gradient {
  background: linear-gradient(45deg, var(--droov-green), var(--droov-blue));
}

/*MyStoreButton styling START*/
/*src: https://codepen.io/kathykato/pen/rZRaNe*/

.my-store-button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  font-size: inherit;
  font-family: inherit;
}
.my-store-button.learn-more {
  width: 12rem;
  height: auto;
}
.my-store-button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  width: 3rem;
  height: 3rem;
  background: linear-gradient(45deg, var(--droov-green), var(--droov-blue));
  border-radius: 0.625rem;
}
.my-store-button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.my-store-button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.my-store-button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}
.my-store-button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 0;
  color: #4b5563;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
}
.my-store-button:hover .circle {
  width: 91%;
}
.my-store-button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}
.my-store-button:hover .button-text {
  color: #fff;
}

/*MyStoreButton styling END*/

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(92, 200, 255, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(92, 200, 255, 0);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-button {
  animation: pulse 2s infinite;
}
.pulse-button:hover {
  animation: none;
}

.pickup-icon svg {
  width: 42px;
  height: 42px;
}

.delivery-icon svg {
  width: 17px;
  height: 17px;
}

.cash-icon {
  width: 24px;
  height: 24px;
}

.card-icon {
  width: 23px;
  height: 23px;
}

/*CUSTOM RANGE DESIGN FOR TIPPING*/

input[type='range'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}
input[type='range']::-moz-range-thumb {
  border: none;
}

.range-style {
  height: 60px;
  padding: 20px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: -2px -2px 8px white, 2px 2px 8px rgba(0, 0, 0, 0.2);
}

/* Safari Chrome browser behavior*/
.range-style::-webkit-slider-runnable-track {
  height: 20px;
  border-radius: 10px;
  box-shadow: inset -2px -2px 8px white, inset 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.range-style::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  top: -10px;
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.01)
  );
  border-radius: 50%;
  box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(0, 0, 0, 0.3);
}

/*Mozzilla browser bahvior*/
.range-style::-moz-range-thumb {
  position: relative;
  top: -50%;
  width: 40px;
  height: 40px;
  background-color: #e0e0e0;
  background-image: linear-gradient(-45deg, rgb(255, 255, 255), transparent);
  border-radius: 50%;
  box-shadow: -1px -1px 2px white, 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.range-style::-moz-range-track {
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: 10px;
  box-shadow: inset -2px -2px 8px white, inset 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.radio-button-input:checked + .radio-button-label {
  @apply border-blue-300;
}

/* customer details */

/* end customer details */

/*END OF CUSTOM RANGE DESIGN FOR TIPPING*/
